<template>
  <v-dialog
    v-model="isLoadingActive"
    persistent
    max-width="600"
    data-v-dialog
    style="box-shadow: none"
    class="elevation-0 rounded-pill"
  >
    <v-card
      flat
      class="pa-8 d-flex align-center justify-center grey--text rounded-pill"
      data-cy="loadingModal"
    >
      <v-progress-circular indeterminate color="primary" size="40" />
      <h3 class="ml-4">{{ message }}</h3>
    </v-card>
  </v-dialog>
</template>

<script>
import LoadingController from "./LoadingController";

export default LoadingController;
</script>

<style lang="scss">
.v-dialog {
  box-shadow: none !important;
}
</style>
