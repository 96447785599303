import { Component, Vue } from "vue-property-decorator";
import feedbackLoadingMessages from "@/utils/feedbackLoadingMessages";

@Component({
  name: "GlobalLoading",
})
export default class LoadingController extends Vue {
  message = feedbackLoadingMessages.default;

  showEventLoading = false;

  get isLoadingActive() {
    return this.$store.getters.isLoading || this.showEventLoading;
  }

  created() {
    this.$bus.$on("openGlobalLoading", () => this.openGlobalLoading());
    this.$bus.$on("closeGlobalLoading", () => this.closeGlobalLoading());
  }

  openGlobalLoading() {
    this.showEventLoading = true;
  }

  closeGlobalLoading() {
    this.showEventLoading = false;
  }
}
